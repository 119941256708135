import classNames from 'classnames';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import { WorkflowLayoutFormWrapperProps } from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.props';

import './workflow-layout-form-wrapper.style.scss';

const WorkflowLayoutFormWrapper = ({
    banner,
    children,
    className,
    eyebrowText,
    title,
    icon,
    isIconContainer = false,
    nav,
    currentFlow
}: WorkflowLayoutFormWrapperProps) => {
    const classes = classNames('workflow-layout-form-wrapper', className);
    const colClasses = classNames(
        'workflow-layout-form-wrapper-header d-flex flex-column justify-content-center align-items-center',
        banner ? 'with-banner' : '',
        currentFlow
    );

    return (
        <div className={classes}>
            <Container fluid>
                {banner && (
                    <Row>
                        <Col className="workflow-layout-form-wrapper-banner">{banner}</Col>
                    </Row>
                )}
                {nav && (
                    <Row>
                        <Col className="workflow-layout-nav-wrapper">{nav}</Col>
                    </Row>
                )}
                <Row>
                    <Col className={colClasses}>
                        {isIconContainer && icon && <div className="header-icon-container">{icon}</div>}
                        {!isIconContainer && <>{icon}</>}
                        {eyebrowText && <div className="h5">{eyebrowText}</div>}
                        {title && (
                            <h1
                                className="h2"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(title, {
                                        allowedTags: ['br']
                                    })
                                }}
                            />
                        )}
                        <div className="spacer" />
                    </Col>
                </Row>
                <Row>
                    <Col className="workflow-layout-form-wrapper-content">{children}</Col>
                </Row>
            </Container>
        </div>
    );
};

export default WorkflowLayoutFormWrapper;
