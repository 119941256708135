// Utils
import { Form, Formik } from 'formik';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';
import Text from 'ui-kit/text/text';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
// UI Kit & Components
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { FORGOT_USERNAME_SCHEMA } from 'schema/forgot-username';

import { getPhoneNumber } from 'util/globalVariables';

import { ForgotUsernameFormValues, ForgotUsernameProps } from './forgot-username-form.props';

export const ForgotUsernameSuccessModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return (
        <BirdiModalContent
            icon="default"
            title={translation('modals.forgotUsernameSuccess.title')}
            body={
                <p
                    className="modal-body-content"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                            translation('modals.forgotUsernameSuccess.body', {
                                phoneNumber: getPhoneNumber({})
                            })
                        )
                    }}
                />
            }
        />
    );
};

export const ForgotUsernameFailureModalHeader = ({ translation }: { translation: TFunction<'translation'> }) => {
    return <BirdiModalHeaderDanger headerText={translation('modals.forgotUsernameFailure.title')} icon="alert" />;
};

export const ForgotUsernameFailureModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return (
        <BirdiModalContentAlt
            subTitle={translation('modals.forgotUsernameFailure.body', {
                phoneNumber: getPhoneNumber({ isEnd: true })
            })}
        />
    );
};

const ForgotUsernameForm = ({ isSubmittingUsernameRequest, onSubmit, style }: ForgotUsernameProps): ReactElement => {
    const { t } = useTranslation();
    const formName = 'ForgotUsernameForm';
    const handleSubmit = (values: Partial<ForgotUsernameFormValues>) => {
        onSubmit(values);
    };

    return (
        <Container fluid style={style}>
            <Row>
                <Col className="d-flex flex-column align-items-center">
                    <p>{t('forgotUsername.labels.formInstructions')}</p>
                    <Formik<Partial<ForgotUsernameFormValues>>
                        onSubmit={handleSubmit}
                        validationSchema={FORGOT_USERNAME_SCHEMA}
                        initialValues={{
                            email: undefined
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="forgot-username-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Text
                                    label={t('forgotUsername.labels.email')}
                                    name="email"
                                    onChange={formik.handleChange}
                                    errors={formik.errors?.email ? t('forgotUsername.errors.username') : undefined}
                                    touched={formik.touched.email}
                                    value={formik.values?.email}
                                    defaultValue={formik.initialValues?.email}
                                    maxLength={255}
                                    type="email"
                                />
                                <div className="d-flex flex-column align-items-center">
                                    <Button
                                        async
                                        className="btn sm-full"
                                        label={t('forgotUsername.labels.submit')}
                                        type="submit"
                                        isBusy={isSubmittingUsernameRequest}
                                        dataGAFormName={formName}
                                    />
                                    <Link
                                        className="d-inline-block mt-4"
                                        to="/sign-in"
                                        label={t('pages.profile.messages.labels.cancel')}
                                        variant="underline-blue-bold"
                                        dataGALocation="loginIssues"
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ForgotUsernameForm;
